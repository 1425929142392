import axios from "axios";
import Collections from "../Utils/collections";
import { AwsClient } from 'aws4fetch'

const API_URL = Collections.ipServer;

// Inicia parametros de servicio de netsuite
const urlNetsuite = "https://3367613.restlets.api.netsuite.com/app/site/hosting/restlet.nl?script=589&deploy=1"

/* * * * SERVICIOSM AWS4 VENTAS * * * */

//const urlVentas = "https://f0cnrg1u3m.execute-api.us-west-1.amazonaws.com/production/";
const aws = new AwsClient({ accessKeyId: 'AKIAXVUP3DWHA3IZXKW5', secretAccessKey: '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' })
const URL = "https://f0cnrg1u3m.execute-api.us-west-1.amazonaws.com/production/query-saved-search-results"
const getIdVentas = async () => {
    let idVentasApi = localStorage.getItem("idApiVentas");
    if(idVentasApi){
        console.log(JSON.parse(idVentasApi).idRqst);
        return await invokeMyLambda({
            "id": JSON.parse(idVentasApi).idRqst
        })//.then(json => console.log(json))
    }
};

async function invokeMyLambda(event) {
    const res = await aws.fetch(URL, { body: JSON.stringify(event) });
    // `res` is a standard Response object: https://developer.mozilla.org/en-US/docs/Web/API/Response
    return res.json();
}
/* * * * FIN SERVICIOSM AWS4 VENTAS * * * */


/*
const consumerKey = "75f8daa5abbefea51f33fadccac26ebb18cf3a06aad1cbdc1a5dde8cc2ae1fd5"
const consumerSecret = "90ce5ba113260b87d5454b9c0fc1b59fea37afe86ade850f486f326530d90ec5"
const token = "1fcea5edf5b5a3967b8a7e21121484e353bd05fe6bc5fcf3b7b6bf53c4f95b17"
const tokenSecret = "92f33a1e4bd8cbd5c976aaf28a82bc8938210a8405b3e315f7851e974084c77e"
const realm = "3367613"
*/
// Fin parametros de servicio de netsuite

const register = (id_user_type_catalog, email, password) => {
    return axios.post(API_URL + "/users/registerUser", {
        id_user_type_catalog,
        email,
        password,
    });
};
const registerUserExtr = (
    nombre, correo, telefono, nombreQuienRecomienda, correoQuienRecomienda, iduQuienCreo, correoQuienCreo, notas
) => {
    return axios.post(API_URL + "/users/registerUserExterno", {
        nombre, correo, telefono, nombreQuienRecomienda, correoQuienRecomienda, iduQuienCreo, correoQuienCreo, notas
    });
};
const updateListaInvitados = (
    idEvento, correoInvitado, asistire
) => {
    return axios.post(API_URL + "/events/updateListaInvitados", {
        idEvento, correoInvitado, asistire
    });
};

const sendMailRyGInvite = (correoInvitado,nombreInvitado, iduPresentador, idPresentador,nombrePresentador) => {
    return axios.post(API_URL + "/mails/inviteRyG", {
        correoInvitado,nombreInvitado, iduPresentador,idPresentador,nombrePresentador
    });
};

const sendMailInvtExtrEvnt = (iduUserCreatedEvent, mailUserCreatedEvent, email, nameEvent, nameUser, fechaEvento, horaEvento, UserID, referenciadoLMS) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    console.log('Current Token is: '+token)
    return axios
        .post(API_URL + '/mails/guestsEvent', {
            iduUserCreatedEvent, mailUserCreatedEvent, email, nameEvent, nameUser, fechaEvento, horaEvento, UserID, referenciadoLMS
        });
};

const sendMailInvtRfrn = (iduUserCreatedEvent, mailUserCreatedEvent, email, nameUser) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
        .post(API_URL + '/mails/referenciados', {
            iduUserCreatedEvent, mailUserCreatedEvent, email, nameUser
        });
};

const sendMailReagendaEvento = (idEvento,iduUserCreatedEvent, nombrePresentador, mailUserCreatedEvent, email, nameEvent,
                                nameUser, fecha, hora, ubicacion) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
        .post(API_URL + '/mails/reagendaEvento', {
            idEvento,iduUserCreatedEvent, nombrePresentador, mailUserCreatedEvent, email, nameEvent, nameUser, fecha, hora, ubicacion
        });
};

const sendMailInvitaEvento= (idEvento, iduUserCreatedEvent, nombrePresentador, mailUserCreatedEvent, email, nameEvent, nameUser, fecha,
                             hora, ubicacion) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
        .post(API_URL + '/mails/invitaEvento', {
            idEvento, iduUserCreatedEvent, nombrePresentador, mailUserCreatedEvent, email, nameEvent, nameUser, fecha, hora, ubicacion
        });
};

const login = (email, password) => {
    return axios
        .post(API_URL + "/users/authenticate", {
            email,
            password,
        })
        .then((response) => {
            //console.log(response.data)
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("id_token", response.data.token);
            }
            return response;
        });
};

const UserStatusCheck = (email) => {
    return axios
        .post(API_URL + "/users/checkuserstatus", {
            email,
        })
        .then((response) => {
            //console.log(response.data)
            if (response.data.checkresult) {
                return true;
            } else {
                return false;
            }
        });
};

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("id_token");
    localStorage.removeItem("openMenu");
    localStorage.removeItem("titleToolbar");
    localStorage.removeItem("enviarAsistencia");
    localStorage.removeItem("userAdmin");
    localStorage.removeItem("idApiVentas");
};

const consult = async (graphqlQuery) => {
    let axiosGraphQL = axios.create({
        baseURL: API_URL + "/thermomix",
        headers: {
            Authorization: 'bearer ' + localStorage.getItem("id_token")
        }
    });
    return await axiosGraphQL.post('', { query: graphqlQuery });
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const uploadImageProfile = (img) => {
    let correo = getCurrentUser().correo
    let token = localStorage.getItem("id_token")
    const data = new FormData();
    data.append("img", img);
    data.append("correo", correo);
    axios.defaults.headers.common["Authorization"] =
        "Bearer " + token;
    return axios.post(Collections.ipServer + "/images/uploadSingle", data)
};

const uploadResource = (img, titulo) => {
    let correo = getCurrentUser().correo
    let token = localStorage.getItem("id_token")
    const data = new FormData();
    data.append("img", img);
    data.append("correo", correo);
    data.append("titulo", titulo);
    axios.defaults.headers.common["Authorization"] =
        "Bearer " + token;
    return axios.post(Collections.ipServer + "/images/uploadResource", data)
};

const deleteResource = (img) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios.post(Collections.ipServer + "/images/deleteResource", {"img": img})
};

const downloadResource = (uri, filename) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios.post(Collections.ipServer + "/images/downloadImage", {"uri": uri, "filename": filename})
};

/* * * * * INICIO ESTADISTICAS * * * * */
//----Nuevas funciones API MDF NOV2022
const getventasrel = (iduUser) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
        .get(API_URL + '/estadistica/ventasrelAPI?idu='+iduUser, {});
};

// FixToDo DevVer:1 Borrar comentarios e integrar como funciones definitivas al terminar.

const getProspectos = (iduUser) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
        .get(API_URL + '/estadistica/prospectos?idu='+iduUser, {});
};
const getExperiencias = (iduUser) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
        .get(API_URL + '/estadistica/experiencias?idu='+iduUser, {});
};
const getVentas = (iduUser) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
        .get(API_URL + '/estadistica/ventas?idu='+iduUser, {});
};
const saveVentas = () => {
    let url = API_URL + '/estadistica/saveVentasAPI'
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
        .get(url, {});
}

/* * * * * FIN ESTADISTICAS * * * * */

const ReportExpNetsuite = (UserExtID) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
    .post(API_URL + '/users/ReportExpNetsuite', {
        UserExtID
    });
};

const getReferenciado = (NetSuiteID, iduQuienCreo) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
    .post(API_URL + '/users/getReferenciado', {
        NetSuiteID, iduQuienCreo
    });
}

const getReferenciadoName = (NetSuiteID) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
    .post(API_URL + '/users/getReferenciadoName', {
        NetSuiteID
    });
}

//To Do VerZ2: Delete this function check if is used.....
/* * * * * * */
const getAllUsersNetsuite = () => {
    axios.post(urlNetsuite,
        {
            "process": "test1"
        },
        {
            mode: 'no-cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json",
                "Authorization": 'OAuth realm="3367613",'+
                'oauth_consumer_key="75f8daa5abbefea51f33fadccac26ebb18cf3a06aad1cbdc1a5dde8cc2ae1fd5",'+
                'oauth_token="1fcea5edf5b5a3967b8a7e21121484e353bd05fe6bc5fcf3b7b6bf53c4f95b17",'+
                'oauth_signature_method="HMAC-SHA1",'+
                //'oauth_timestamp="1620340242",'+
                //'oauth_nonce="THsvIe",'+
                'oauth_signature="Zrg%2FhSxTKzga6%2F3GVkd7yz2spI4%3D"'
        }})
    .then((res) => {
        console.log(res)
    })
    .catch((error) => {
        console.error(error)
    })
}

const GetActiveNotifications = (idu) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
    .post(API_URL + '/users/GetNotificationsByIDU', {
        idu
    });
};

const UpdateNotificationStatus = (uniqueID, notificationStatus) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
    .post(API_URL + '/users/UpdateNotificationStatus', {
        uniqueID, notificationStatus
    });
};

const CambioPresentadorQrry = (notificationsID) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
    .post(API_URL + '/users/CambioPresentadorQrry', {
        notificationsID
    });
};

const checkRyGinvite = (correo, iduQuienCreo) => {
    let token = localStorage.getItem("id_token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    return axios
    .post(API_URL + '/users/checkRyGexist', {
        correo, iduQuienCreo
    });
};

/* * * * * * */

export default {
    register,
    registerUserExtr,
    login,
    UserStatusCheck,
    logout,
    consult,
    getCurrentUser,
    getAllUsersNetsuite,
    uploadImageProfile,
    uploadResource,
    updateListaInvitados,
    deleteResource,
    downloadResource,
    sendMailInvtExtrEvnt,
    sendMailInvtRfrn,
    sendMailReagendaEvento,
    sendMailInvitaEvento,
    getProspectos,
    getExperiencias,
    getVentas,
    getIdVentas,
    saveVentas,
    getventasrel,
    ReportExpNetsuite,
    GetActiveNotifications,
    UpdateNotificationStatus,
    getReferenciado,
    getReferenciadoName,
    CambioPresentadorQrry,
    sendMailRyGInvite,
    checkRyGinvite
};