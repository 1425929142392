import React, {useState, useEffect, useRef} from 'react';
import AuthService from "../Services/AuthService";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
//import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import SendIcon from '@material-ui/icons/Send';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";
//import es from "date-fns/locale/es"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"
import Estadisticas from "./Estadisticas"
import EstructuraObjetivos from "./EstructuraObjetivos"
import utils from "../Utils/collections";
//import calendarhandler from "../Utils/vorwerkcalendarhandler";
//To Do Ver6: End the integration of handlermeses or delete this lines and the js file.
//import vorwerkcalendarhandlermeses from "../Utils/vorwerkcalendarhandlermeses";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GreenCheckbox = withStyles({
    root: {
        color: "#808080",
        '&$checked': {
            color: "#00AC46",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function Dashboard(props) {

    const [classes] = useState(props.classes);
    const [eventDate, setEventDate] = useState(new Date());
    const [eventDateHasta, setEventDateHasta] = useState(new Date());
    const [currentUser] = useState(props.user);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [openReferModal, setOpenReferModal] = useState(false);
    const [usrsIntr, setUsrsIntr] = useState([]);
    const [usrs, setUsrs] = useState([]);
    const [usrsRspl, setUsrsRspl] = useState([]);
    const [usrsBscr, setUsrsBscr] = useState("");
    const [checkAll, setCheckAll] = useState(false);
    const [enviandoCorreo, setEnviandoCorreo] = useState(false);
    const [dataRfrn, setDataRfrn] = useState([]);
    const [dataEvnt, setDataEvnt] = useState([]);
    const [calendarioVorwerk, setCalendarioVorwerk] = useState([]);
    const botonGeneraReporteConsultaEvento = useRef(null);
    const botonGeneraReporteConsultaReferenciados = useRef(null);
    const [load, setLoad] = useState(false);
    const meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio',
        'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
    // eslint-disable-next-line no-unused-vars
    //To Do Ver6: Remove this unused vars from utils.
    //const fechaVorwerk = utils.calendarioVorwerk[new Date().getMonth()][new Date().getMonth()][1].fin;
    const [fechaVorwerkDate, setFechaVorwerkDate] = useState(utils.calendarioVorwerk[new Date().getMonth()][new Date().getMonth()]);
    const [CurrentFecha, setCurrentFecha] = useState([]);
    const [CurrentMesNum, setCurrentMesNum] = useState(0);
    const [CurrentAnioNum, setCurrentAnioNum] = useState(0);



    //const arrUserTest = "6617,8658,8360,7231,8118,6063,3119,6611,5590,5136";

    useEffect(()=>{
        consultaUsuarios(currentUser.rol[0].value===0? currentUser.correo: currentUser.idu);



        //Aqui vamos a consultar el servicio de calendarioVorwerk
        console.log("Consulta de calendario vorwerk Desde Inicio")
        //To Do Ver2: Set the Calendar Qerry From an unified function from the backend....
        let gqlClndVrwr = `{
            vorwerkcalendar{
                id
                calendario2022 {
                    nombreMes
                    fechaInicio
                    fechaFin
                }
                calendario2023 {
                    nombreMes
                    fechaInicio
                    fechaFin
                }
                calendario2024 {
                    nombreMes
                    fechaInicio
                    fechaFin
                }
                calendario2025 {
                    nombreMes
                    fechaInicio
                    fechaFin
                }
            }
        }`

        //To Do Ver3: This a Commit test.
        AuthService.consult(gqlClndVrwr)
        .then(result=>{
            let calendario = result.data.data.vorwerkcalendar
            // Esta consulta debe ser en base al mes Vorwerk
            //let fecha = new Date();
            //To Do Ver2: This part uses the old calendar dates by variables Must be removed.
            if(calendario && calendario.length>0){console.log('El valor de fecha fase 1 es: '.fecha)}
            //let fechaVorwerk = utils.calendarioVorwerk[fecha.getMonth()][fecha.getMonth()][1].fin;
            //let fechaVorwerkInicio = utils.calendarioVorwerk[fecha.getMonth()][fecha.getMonth()][0].inicio;
            //let mesConsultar = fecha.getMonth()+1
            let fechaVorwerkActual = [{'inicio': ''}, {'fin': ''}]

            //To Do Ver6: Remove this validation to consolelog of fechaVorwerkActual;

            function prettyJSONv(obj) {
                let stringed = JSON.stringify(obj, null, 2);
                return stringed
            }
            let CalendarToJson = prettyJSONv(fechaVorwerkActual);

            console.log('BNDene2025 deprecated Ver6: fechaVorwerkActual form this section (inicio.js) is: '+CalendarToJson);

            //To Do Ver2: END OF - This part uses the old calendar dates by variables Must be removed.
            if(calendario && calendario.length>0){
                //console.log("Inicio.js Existe calendario 2022", calendario[0].calendario2022)
                //console.log("Inicio.js Existe calendario 2023", calendario[0].calendario2023)

                //To Do Ver2: Add the mutator and Fecha global as export functions...
                function TimeZoneMutator(numOfHours, date) {
                    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
                
                    return date;
                  }
                  function GetFechaGlobal(numOfHours, date = new Date()) {
                    date.setTime(date.getTime() - numOfHours * 60 * 60 * 1000);
                
                    return date;
                  }
                
                
                

                //To Do Ver6: Check why this mutators are no longer used:
                /*
                var ToMutateFin2022 = new Date (calendario[0].calendario2022[11].fechaFin)
                var ToMutateFin2023 = new Date (calendario[0].calendario2023[11].fechaFin)
                const Fin2022 = TimeZoneMutator(6,ToMutateFin2022);
                const Fin2023 = TimeZoneMutator(6,ToMutateFin2023);
                */

                //To Do Ver2: Delete this console logs....
                //console.log('El final de 2022 es: ' + Fin2022);
                //console.log('El final de 2023 es: ' + Fin2023);

                //To Do Ver2: Current Date can be changed here for test in front.
                //To Do Release: Horas sumadas deben ser 0 para no ajustar fecha actual.
                var CurrentFecha = GetFechaGlobal(0);
                setCurrentFecha(CurrentFecha)
                //console.log('La fecha actual es: ' + CurrentFecha);

                var CurrentAnio = 2021;
                var CurrentMes = '';
                var MesID = 0;
                if(CurrentAnio < 2022){
                    //console.log('Revisando si las fechas corresponden a 2022.')
                    MesID = 0;
                    calendario[0].calendario2022.forEach(mes=>{
                        //console.log("mes", mes) To Do ver2: Delete this comment from older programmer.
                        MesID++;
                        //To Do Ver6: Unify calendar functions to calendar handler.
                        //console.log('El Mes ID va en: ' + MesID)
                        let ToMutatePorMesInicio = new Date(mes.fechaInicio)
                        let ToMutatePorMesFin = new Date(mes.fechaFin)
                        let ComparafechaVorwerkInicio = TimeZoneMutator(6,ToMutatePorMesInicio);
                        let ComparafechaVorwerkFin = TimeZoneMutator(6,ToMutatePorMesFin);
                        if( CurrentFecha.getTime() > ComparafechaVorwerkInicio.getTime() && CurrentFecha.getTime() < ComparafechaVorwerkFin.getTime() ) {
                           fechaVorwerkActual = [{'inicio': ComparafechaVorwerkInicio}, {'fin': ComparafechaVorwerkFin}]
                            //console.log('FechaVorwerkActual Encontrada en 2022: ' + ComparafechaVorwerkInicio + ' / ' + ComparafechaVorwerkFin);
                            CurrentAnio++;
                            CurrentMes = mes.nombreMes;
                            setCalendarioVorwerk(calendario[0].calendario2022)
                            setCurrentMesNum(MesID)
                            setCurrentAnioNum(CurrentAnio)
                        }
                    })
            }
            if (CurrentAnio < 2022){
                //console.log('No se encontró coincidencias en 2022, verificando con 2023.')
                MesID = 0;
                    calendario[0].calendario2023.forEach(mes=>{
                        //console.log("mes", mes) To Do ver2: Delete this comment from older programmer.
                        MesID++;
                        //To Do Ver6: Unify calendar queries to utils calendar handler.
                        //console.log('El Mes ID va en: ' + MesID)
                        let ToMutatePorMesInicio = new Date(mes.fechaInicio)
                        let ToMutatePorMesFin = new Date(mes.fechaFin)
                        let ComparafechaVorwerkInicio = TimeZoneMutator(6,ToMutatePorMesInicio);
                        let ComparafechaVorwerkFin = TimeZoneMutator(6,ToMutatePorMesFin);
    
                        if( CurrentFecha.getTime() > ComparafechaVorwerkInicio.getTime() && CurrentFecha.getTime() < ComparafechaVorwerkFin.getTime() ) {
                            fechaVorwerkActual = [{'inicio': ComparafechaVorwerkInicio}, {'fin': ComparafechaVorwerkFin}]
                            //console.log('FechaVorwerkActual Encontrada en 2023: ' + ComparafechaVorwerkInicio + ' / ' + ComparafechaVorwerkFin);
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentMes = mes.nombreMes;
                            setCalendarioVorwerk(calendario[0].calendario2023)
                            setCurrentMesNum(MesID);
                            setCurrentAnioNum(CurrentAnio);
                        }
                    })
            }
            if (CurrentAnio < 2022){
                //console.log('No se encontró coincidencias en 2022, verificando con 2023.')
                MesID = 0;
                    calendario[0].calendario2024.forEach(mes=>{
                        //console.log("mes", mes) To Do ver2: Delete this comment from older programmer.
                        MesID++;
                        //To Do Ver6: Unify calendar queries to utils calendar handler.
                        //console.log('El Mes ID va en: ' + MesID)
                        let ToMutatePorMesInicio = new Date(mes.fechaInicio)
                        let ToMutatePorMesFin = new Date(mes.fechaFin)
                        let ComparafechaVorwerkInicio = TimeZoneMutator(6,ToMutatePorMesInicio);
                        let ComparafechaVorwerkFin = TimeZoneMutator(6,ToMutatePorMesFin);
    
                        if( CurrentFecha.getTime() > ComparafechaVorwerkInicio.getTime() && CurrentFecha.getTime() < ComparafechaVorwerkFin.getTime() ) {
                            fechaVorwerkActual = [{'inicio': ComparafechaVorwerkInicio}, {'fin': ComparafechaVorwerkFin}]
                            console.log('FechaVorwerkActual Encontrada en 2024: ' + ComparafechaVorwerkInicio + ' / ' + ComparafechaVorwerkFin);
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentMes = mes.nombreMes;
                            setCalendarioVorwerk(calendario[0].calendario2024)
                            setCurrentMesNum(MesID);
                            setCurrentAnioNum(CurrentAnio);
                        }
                    })
            }
            if (CurrentAnio < 2022){
                //console.log('No se encontró coincidencias en 2022, verificando con 2023.')
                MesID = 0;
                    calendario[0].calendario2025.forEach(mes=>{
                        //console.log("mes", mes) To Do ver2: Delete this comment from older programmer.
                        MesID++;
                        //To Do Ver6: Unify calendar queries to utils calendar handler.
                        //console.log('El Mes ID va en: ' + MesID)
                        let ToMutatePorMesInicio = new Date(mes.fechaInicio)
                        let ToMutatePorMesFin = new Date(mes.fechaFin)
                        let ComparafechaVorwerkInicio = TimeZoneMutator(6,ToMutatePorMesInicio);
                        let ComparafechaVorwerkFin = TimeZoneMutator(6,ToMutatePorMesFin);
    
                        if( CurrentFecha.getTime() > ComparafechaVorwerkInicio.getTime() && CurrentFecha.getTime() < ComparafechaVorwerkFin.getTime() ) {
                            fechaVorwerkActual = [{'inicio': ComparafechaVorwerkInicio}, {'fin': ComparafechaVorwerkFin}]
                            console.log('FechaVorwerkActual Encontrada en 2025: ' + ComparafechaVorwerkInicio + ' / ' + ComparafechaVorwerkFin);
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentAnio++;
                            CurrentMes = mes.nombreMes;
                            setCalendarioVorwerk(calendario[0].calendario2025)
                            setCurrentMesNum(MesID);
                            setCurrentAnioNum(CurrentAnio);
                        }
                    })
            }
            if (CurrentAnio < 2022){
                console.log('No se encontro el año actual. Iniciar proceso de ERROR FATAL (inicio.js).')
            }
            
            
                console.log('(from inicio.js) El mes Vorwerk actual es: '+ CurrentMes + ' del año: ' + CurrentAnio);


                //To Do Ver2: Correct functions are from this line UP------------
                console.log('-------------------------------------------------------------------------------------------------')

            } else {
                console.log("ERROR: Inicio.js No existe calendario...")
            }

            console.log("fechaVorwerkActual desde Inicio.js --> ", fechaVorwerkActual)
            setFechaVorwerkDate(fechaVorwerkActual)
        })
        .catch(error=>{
            console.log(error);
        })
    }, []);

    const alerts = (msg) =>{
        toast(msg, {
            className: css({
                background: "#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: "#FFF"
            })
        });
    };

    const handleChangeEventDate = (date) =>{
        console.log('Fecha inicial de reporte es: ');
        console.log(date);
        setEventDate(date);
    };
    const handleChangeEventDateHasta = (date) =>{
        console.log('Fecha final de reporte es: ');
        console.log(date);
        setEventDateHasta(date);
    };

    const consultaUsuarios = (correo) => {
        AuthService.consult(`{userExternos(iduQuienCreo:"${correo}"){
            id idu:id nombre correo telefono categoria activo perfil nombreQuienRecomienda correoQuienRecomienda 
                iduQuienCreo correoQuienCreo noPedido reclutamiento fechaCambioCliente fechaCambioReclutamiento created}}`)
            .then(result=>{
                //console.log(result.data.data.userExternos);
                result.data.data.userExternos.map((el, i)=>{
                    return Object.assign(el, {"checked": false, "esExtr": true})
                })
                setUsrs(result.data.data.userExternos);
                setUsrsRspl(JSON.parse(JSON.stringify(result.data.data.userExternos)))

            })
            .catch(error=>{
                console.log(error);
            })
        AuthService.consult(`{users{
                idu nombre apellido rol { value text }
                estructura {
                  idu
                  nombre
                  apellido
                  correo
                  rol {
                    value
                    text
                  }
                }
            }}`)
        .then(usrsI=>{
                setUsrsIntr(usrsI.data.data.users)
            setLoad(true);
        }).catch(err=>console.log(err));

    };

    const consultaReferenciadosReporte = () => {
        setLoad(false);
        AuthService.consult(`{usersExternosAllRfrn{
                id idu:id nombre correo telefono categoria activo perfil nombreQuienRecomienda correoQuienRecomienda 
                iduQuienCreo correoQuienCreo noPedido reclutamiento fechaCambioCliente fechaCambioReclutamiento created}}`)
            .then(result=>{
                let usrsR = result.data.data.usersExternosAllRfrn;

                let arrRfrn = [];
                usrsR.forEach(usr=>{
                    let usroPrsn = usrsIntr.filter(u=>u.idu===usr.iduQuienCreo)
                    let objUsrRfr = {
                        idu: usr.iduQuienCreo,
                        presentador: (usroPrsn.length>0 ? (usroPrsn[0].nombre + ' ' + usroPrsn[0].apellido): ''),
                        nombrerecomendado: usr.nombre,
                        telefonorecomendado: usr.telefono,
                        correorecomendado: usr.correo,
                        correoquienhizorecomendacion: usr.correoQuienRecomienda,
                        fecharecibiorecomendacion: new Date(usr.created).toLocaleDateString(
                            'es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }
                        )
                    };
                    arrRfrn.push(objUsrRfr);
                });
                setDataRfrn(arrRfrn);
                botonGeneraReporteConsultaReferenciados.current.link.click();
                setLoad(true);
            })
            .catch(error=>{
                console.log(error);
            })
    }

    const consultaEventosReporte = () => {
        //To Do Ver2: Change date parameters to real Vorwerk Dates.
        setLoad(false);
        if(currentUser.rol[0].value===0){
            let fechaDesde = null
            let fechaHasta = null
            if(eventDate.getTime()===eventDateHasta.getTime()){
                let fecha = new Date();
                // Esta consulta debe ser en base al mes Vorwerk
                let fechaVorwerkR = utils.calendarioVorwerk[fecha.getMonth()][fecha.getMonth()];
                let fechaFin = fechaVorwerkR[1].fin;
                if( fecha.getTime() > fechaFin.getTime() ) {
                    // si la fecha de hoy es mayor a la fecha fin de vorwerk
                    if(fecha.getMonth()+1 === 12){
                        fechaVorwerkR = utils.calendarioVorwerk[0][0];
                    } else {
                        fechaVorwerkR = utils.calendarioVorwerk[fecha.getMonth()+1][fecha.getMonth()+1];
                    }
                }
                fechaDesde = fechaVorwerkR[0].inicio
                fechaHasta = fechaVorwerkR[1].fin
            } else {
              fechaDesde = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate())
              fechaHasta = new Date(eventDateHasta.getFullYear(), eventDateHasta.getMonth(), eventDateHasta.getDate(), 23, 59)
            }
            //console.log("fechaDesde -> ", fechaDesde)
            //console.log("fechaHasta -> ", fechaHasta)
            AuthService.consult(`{eventsMesVorwerk(
                start: "${fechaDesde}",
                end: "${fechaHasta}"
                ){
                  id
                  titulo
                  fecha
                  color
                  start
                  end
                  idTipo
                  tipo
                  modalidad1
                  modalidad2
                  notas
                  direccion
                  listaInvitados {
                    idu
                    nombre
                    correo
                    segmento
                    esExtr
                    sendMail
                    asistire
                  }
                  iduPresentador
                  correoPresentador
                  seRepite
                  frecuencia
                  seRepiteHasta
                  idEventoLigado
                  status
                  created_event
                  idAppointment
                  fechaReagendaFormato
                  horaInicioReagenda
                }}`)
                .then(result=>{
                    let evntAll = result.data.data.eventsMesVorwerk;

                    let arrEvnts = [];
                    evntAll.forEach(evnt=>{
                        if(evnt){
                            let usroPrsn = usrsIntr.filter(u=>u.idu===evnt.iduPresentador)
                            //console.log(usroPrsn)
                            if(usroPrsn.length>0){
                                let realizado = evnt.listaInvitados ?
                                    (evnt.listaInvitados.filter(invt=>invt.sendMail===true).length>0? 'Realizado': 'Agendado') :
                                    "Agendado";
                                let objUsrRfr = {
                                    id: evnt.id,
                                    idu: evnt.iduPresentador,
                                    presentador: (usroPrsn.length>0 ? (usroPrsn[0].nombre + ' ' + usroPrsn[0].apellido): ''),
                                    rol: (usroPrsn.length>0 ? usroPrsn[0].rol[0].text: ''),
                                    tipoevento: evnt.tipo,
                                    categoria1: evnt.modalidad1,
                                    categoria2: evnt.modalidad2,
                                    fechacreacionevento: new Date(evnt.created_event).toLocaleDateString(),
                                    fechaejecucionevento: new Date(evnt.start).toLocaleString(),
                                    iduliderequipo: usroPrsn.length>0 ? getIduNameEstructura(usroPrsn[0].estructura, 3, true): '',
                                    liderequipo: usroPrsn.length>0 ? getIduNameEstructura(usroPrsn[0].estructura, 3, false): '',
                                    idugerente: usroPrsn.length>0 ? getIduNameEstructura(usroPrsn[0].estructura, 5, true): '',
                                    gerente: usroPrsn.length>0 ? getIduNameEstructura(usroPrsn[0].estructura, 5, false): '',
                                    iduareamanager: usroPrsn.length>0 ? getIduNameEstructura(usroPrsn[0].estructura, 8, true): '',
                                    areamanager: usroPrsn.length>0 ? getIduNameEstructura(usroPrsn[0].estructura, 8, false): '',
                                    idusalesdirector: usroPrsn.length>0 ? getIduNameEstructura(usroPrsn[0].estructura, 9, true): '',
                                    salesdirector: usroPrsn.length>0 ? getIduNameEstructura(usroPrsn[0].estructura, 9, false): '',
                                    estatus: evnt.status===0 ? 'Cancelado' : evnt.status===3 ? 'No se realizo':
                                        (evnt.status===2 && realizado==='Realizado') ? realizado: evnt.status===2 ? 'Reagendado' : realizado
                                };
                                arrEvnts.push(objUsrRfr);
                            }
                        }
                    });

                    setDataEvnt(arrEvnts);
                    botonGeneraReporteConsultaEvento.current.link.click();
                    setLoad(true);

                })
                .catch(error=>{
                    console.log(error);
                })


        }
    }

    const getIduNameEstructura = (estructura, idRol, getIdu) =>{
        let iduName = '';
        if(estructura.length>0){
            let datos = estructura.filter(est=>est.rol[0].value===idRol);
            if(datos.length>0){
                iduName = getIdu ? datos[0].idu: (datos[0].nombre + ' ' + datos[0].apellido)
            }
        }
        return iduName
    };

    const handleCheckAll = (event) => {
        setCheckAll(event.target.checked)
        usrs.map((el,i)=>{
            return el.checked=event.target.checked
        })
    };

    const handleCheckOne = (event, id) => {
        //console.log(id+ " vamos a checar uno por uno ..." + event.target.checked)
        setCheckAll(false)
        let nU = []
        usrs.forEach((el,i)=>{
            if(el.id===id){
                el.checked = event.target.checked
            }
            nU.push(el)
        })
        setUsrs(nU)
    };

    const handleOpenReferModal = () => {
        setOpenReferModal(true);
    };

    const handleCloseReferModal = () => {
        setOpenReferModal(false);
    };

    const handleChangeBscr = (ev) => {
        console.log(ev.target.value)
        setUsrsBscr(ev.target.value)
    };

    const handleBuscar = () => {
        console.log("Buscar: " + usrsBscr)
        if(usrsBscr.length>0){
            let findUsrs = usrs.filter(usr=>usr.nombre.indexOf(usrsBscr)>=0)
            setUsrs(findUsrs);
        } else {
            setUsrs(usrsRspl);
        }
    };

    const enviarInvitacion = () => {
        let usrsCheck = usrs.filter(usr=>usr.checked);
        setEnviandoCorreo(true);
        usrsCheck.forEach((usr, i)=>{
                console.log("enviando invitación " + usr.correo);
                //mandar correo de reagendados
                AuthService.sendMailInvtRfrn((currentUser.rol[0].value === 0 ? currentUser.correo: currentUser.idu),
                    currentUser.correo, usr.correo, usr.nombre)
                .then(resp => {
                    console.log(resp);
                    if (i === usrsCheck.length - 1) {
                        alerts("Se ha enviado correo a los usuarios seleccionados.");
                        setOpenReferModal(false);
                        setEnviandoCorreo(false);
                        usrs.map((el,i)=>{
                            return el.checked=false
                        })
                    }
                })
                .catch(error=>{console.log(error)});
        })

    };

    const headersEvnt = [
        { label: "ID", key: "id" },
        { label: "IDU", key: "idu" },
        { label: "Nombre", key: "presentador" },
        { label: "Rol", key: "rol" },
        { label: "Tipo de Evento", key: "tipoevento" },
        { label: "Categoria 1", key: "categoria1" },
        { label: "Categoria 2", key: "categoria2" },
        { label: "Fecha de creación del evento", key: "fechacreacionevento" },
        { label: "Fecha de ejecución del evento", key: "fechaejecucionevento" },
        { label: "IDU (Lider de Equipo)", key: "iduliderequipo"},
        { label: "Lider de Equipo", key: "liderequipo"},
        { label: "IDU (Gerente)", key: "idugerente"},
        { label: "Gerente", key: "gerente"},
        { label: "IDU (Área Manager)", key: "iduareamanager"},
        { label: "Área Manager", key: "areamanager"},
        { label: "IDU (Sales Director)", key: "idusalesdirector"},
        { label: "Sales Director", key: "salesdirector"},
        { label: "Estatus", key: "estatus" }
    ];

    const headersRfrn = [
        { label: "IDU", key: "idu" },
        { label: "Presentador al que le llego la recomendación", key: "presentador" },
        { label: "Nombre del Recomendado", key: "nombrerecomendado" },
        { label: "Teléfono del Recomendado", key: "telefonorecomendado" },
        { label: "Correo del Recomendado", key: "correorecomendado" },
        { label: "Correo de quien hizo la recomendación", key: "correoquienhizorecomendacion" },
        { label: "Fecha en la cual se recibió la recomendación", key: "fecharecibiorecomendacion" },

    ];

    return (
        <div>
            <CSVLink
                ref={botonGeneraReporteConsultaEvento}
                filename={"ReporteEventos.csv"}
                style={{
                    padding: "10px", margin: "10px", backgroundColor: "#00AC46",
                    borderRadius: "10px", color: "white", display: "none"
                }}
                data={dataEvnt} headers={headersEvnt}
            >
                Reporte eventos
            </CSVLink>
            <CSVLink
                ref={botonGeneraReporteConsultaReferenciados}
                filename={"ReporteReferenciados.csv"}
                data={dataRfrn} headers={headersRfrn}
                style={{
                    padding: "10px", margin: "10px", backgroundColor: "#00AC46",
                    borderRadius: "10px", color: "white", display: "none"
                }}>
                Reporte referenciados
            </CSVLink>
            {
                !load ?
                    <Lottie
                        loop
                        animationData={loading}
                        play
                        style={{width: 450, height: 450, display: "block", margin: "auto"}}
                    />
                    :
                    <Grid container spacing={3}>
                        {/* Chart */}
                        <Grid item xs={12} md={12} lg={12}>

                            <div>
                                <p style={{fontSize: "20px", textAlign: "center"}} className="">
                                      
                                  Bienvenid@ 
                                  <span style={{fontSize: "20px", fontWeight: "bolder", color:"#00AC46"}}>
                                  {' ' + currentUser.nombre + ' ' + (currentUser.apellido && currentUser.apellido.length>0 ? currentUser.apellido: '')}
                                  </span><br/> 
                                  El día de hoy es: {CurrentFecha.getDate()} de {meses[CurrentFecha.getMonth()]} del {CurrentFecha.getFullYear()}
                                  <br/>
                                    {'Estás a '+
                                     parseInt(( (new Date(fechaVorwerkDate[1].fin)).getTime()-CurrentFecha.getTime())/1000/60/60/24) + ' ' +
                                    'días del cierre de mes.'}
                                    <br/>
                                    <br/>
                                    {'El cierre de mes es el: ' + (new Date(fechaVorwerkDate[1].fin)).getDate() + ' de ' + 
                                     meses[(new Date(fechaVorwerkDate[1].fin)).getMonth()] + ' del ' + (new Date(fechaVorwerkDate[1].fin)).getFullYear()}
                                </p>
                            </div>
                            {
                                (
                                    //arrUserTest.indexOf(currentUser.idu.toString())>=0 &&
                                    (currentUser.rol[0].value === 1 ||
                                        currentUser.rol[0].value === 3 ||
                                        currentUser.rol[0].value === 5) ) ?
                                <div className={'stats'}>
                                    <Estadisticas user={currentUser} userIntr={usrsIntr} calendarioVorwerk={calendarioVorwerk} CurrentFecha={CurrentFecha} CurrentMesNum={CurrentMesNum} CurrentAnioNum={CurrentAnioNum}/>
                                </div>
                                :
                                <div className={'helloUser'} style={{borderStyle:"solid", borderRadius: "15px"}}>
                                    {
                                        currentUser.rol[0].value === 0 &&
                                        <div className="row"  style={{padding: "20px"}}>
                                            <div className='col-lg-12' style={{fontSize:"16px"}}>
                                              <b>Instrucciones: </b>Elegir un rango de fechas para generar el reporte de eventos, si no se elige un rango de fechas, el reporte se generará en base al inicio y cierre de cada mes.
                                            </div>
                                            <div className='row'>
                                              <div className='col l4 m5 s12'>
                                                <DatePicker
                                                    className="inputDateNewEvent"
                                                    name="eventDate"
                                                    locale="es"
                                                    placeholderText="Fecha de evento"
                                                    dateFormat="EEEE d 'de' MMMM 'de' y"
                                                    selected={eventDate}
                                                    onChange={handleChangeEventDate}
                                                    modalTransparent={false}
                                                    animationType={"fade"}
                                                />
                                              </div>
                                              <div className='col l1 m2 s12' style={{textAlign: "center", marginTop: "10px", fontSize: "16px"}}>
                                                a
                                              </div>
                                              <div className='col l4 m5 s12'>
                                                <DatePicker
                                                    className="inputDateNewEvent"
                                                    name="eventDate"
                                                    locale="es"
                                                    placeholderText="Fecha de evento"
                                                    dateFormat="EEEE d 'de' MMMM 'de' y"
                                                    selected={eventDateHasta}
                                                    onChange={handleChangeEventDateHasta}
                                                    modalTransparent={false}
                                                    animationType={"fade"}
                                                />
                                              </div>
                                            </div>
                                            <div align="center">
                                              <Button style={{
                                                  padding: "10px", margin: "10px", backgroundColor: "#00AC46",
                                                  borderRadius: "10px", color: "white"
                                              }}
                                                      onClick={consultaEventosReporte}
                                              >
                                                  Reporte eventos
                                              </Button>
                                              <Button style={{
                                                  padding: "10px", margin: "10px", backgroundColor: "#00AC46",
                                                  borderRadius: "10px", color: "white"
                                              }}
                                                      onClick={consultaReferenciadosReporte}
                                              >
                                                  Reporte referenciados
                                              </Button>
                                            </div>
                                        </div>
                                    }
                                    

                                    {
                                        currentUser.rol[0].value === 0 ?
                                            <img hidden src="/assets/img/test-reffered.png" className="comingSoonImg"
                                                 alt="proximamenteAgenda" onClick={handleOpenReferModal}/>
                                            :
                                            <img hidden src="/assets/img/home_thermomix1.jpg" className="comingSoonImg"
                                                 alt="proximamenteAgenda"/>
                                    }

                                </div>
                            }
                        </Grid>
                        {
                            (currentUser.rol[0].value===0 || currentUser.rol[0].value===5 || currentUser.rol[0].value===3) &&
                            <Grid item xs={12} md={12} lg={12}>
                                <div className={fixedHeightPaper}>
                                    <EstructuraObjetivos user={currentUser} userIntr={usrsIntr} calendarioVorwerk={calendarioVorwerk} CurrentFecha={CurrentFecha} CurrentMesNum={CurrentMesNum} CurrentAnioNum={CurrentAnioNum}/>
                                </div>
                            </Grid>
                        }
                        <Dialog open={openReferModal} onClose={handleCloseReferModal} TransitionComponent={Transition}>
                            <div className="row">
                                <h3 className="nomargin">Recomienda y gana con Thermomix®</h3>
                                <p>Invita a tus clientes y prospectos a participar en la campaña de Recomienda y Gana
                                    con Thermomix; cada recomendación es una oportunidad para conseguir increíbles
                                    premios</p>
                            </div>
                            <div className="row">
                                <div className="col l10 s10">
                                    <input type="text" placeholder="Buscar contacto externo..."
                                           className="searchExtInput"
                                           onChange={(ev) => handleChangeBscr(ev)}/>
                                </div>
                                <div className="col l2 s2">
                                    <Button onClick={handleBuscar}
                                            style={{
                                                padding: "5px", backgroundColor: "#00AC46",
                                                borderRadius: "10px", color: "white"
                                            }}>
                                        <SearchIcon style={{color: "white"}}/>
                                    </Button>
                                </div>
                                <div className="col l12 s12">
                            <span className="searchExtInputInfo">
                                {
                                    usrs.length === 1 ? ' 1 usuario encontrado' :
                                        usrs.length + ' usuarios encontrados'
                                }
                            </span>
                                </div>
                            </div>
                            <div>
                                <List>
                                    <ListItem button>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Seleccionar todos"/>
                                        <ListItemSecondaryAction>
                                            <FormGroup row className="col l4">
                                                <FormControlLabel
                                                    control={
                                                        <GreenCheckbox checked={checkAll}
                                                                       onChange={handleCheckAll} name="all"/>}
                                                    label=""
                                                />
                                            </FormGroup>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider/>
                                    {
                                        usrs.map((usr, i) =>
                                            <div key={i}>
                                                <ListItem button>
                                                    <ListItemIcon>
                                                        <AccountCircleIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={usr.nombre} secondary={usr.correo}/>
                                                    <ListItemSecondaryAction>
                                                        <FormGroup row className="col l4">
                                                            <FormControlLabel
                                                                control={
                                                                    <GreenCheckbox checked={usr.checked}
                                                                                   style={{paddingTop: "0px"}}
                                                                                   onChange={(ev) => handleCheckOne(ev, usr.id)}
                                                                                   name={"user" + usr.id}/>}
                                                                label=""
                                                            />
                                                        </FormGroup>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider/>
                                            </div>
                                        )
                                    }

                                </List>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <div className="row">
                                {
                                    !enviandoCorreo &&
                                    <Button onClick={enviarInvitacion}
                                            variant="contained"
                                            style={{backgroundColor: "rgb(0, 154, 61)", color: "white"}}
                                            className={classes.button}
                                            startIcon={<SendIcon/>}
                                    > Enviar invitación
                                    </Button>
                                }
                            </div>
                        </Dialog>

                        {/* Recent Deposits */}
                        {/* <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <p style={{textAlign: "center", color: "#00AC46", fontSize: "15px"}}>Próximamente..</p>
                                <img src="/assets/img/tm6-greenblue.png" className="comingSoonImg"
                                     alt="proximamenteAgenda"/>
                            </Paper>
                        </Grid> */}

                        {/* Recent Orders */}
                        {/* <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <p style={{textAlign: "center", color: "#00AC46", fontSize: "15px"}}>Próximamente..</p>
                            </Paper>
                        </Grid> */}
                    </Grid>
            }
        </div>
    );
}