// FIXME: Revisar el nombre de Junta General
const eventosAll = [
    {idSrvcOnsch:"19870", idTipo: 1, nombre: "Demo Thermomix", modalidad1: true, modalidad2: true, editaTitulo: false, periodicidad: false},
    {idSrvcOnsch:"19871", idTipo: 2, nombre: "Entrega Thermomix", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: false},
    {idSrvcOnsch:"19895", idTipo: 3, nombre: "Contactar", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
    {idSrvcOnsch:"19896", idTipo: 4, nombre: "Seguimiento", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
    {idSrvcOnsch:"19897", idTipo: 5, nombre: "Evento Personal", modalidad1: false, modalidad2: false, editaTitulo: true, periodicidad: false},
    {idSrvcOnsch:"19898", idTipo: 6, nombre: "Junta General", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true},
    {idSrvcOnsch:"19899", idTipo: 7, nombre: "Clases de Cocina", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
    {idSrvcOnsch:"19900", idTipo: 8, nombre: "Workshop Interno", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true},
    {idSrvcOnsch:"19901", idTipo: 9, nombre: "Junta de Líderes de Equipo", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
    {idSrvcOnsch:"19902", idTipo: 10, nombre: "Junta de Ventas", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
    {idSrvcOnsch:"19903", idTipo: 11, nombre: "Taller de Conocimiento de Producto", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
    {idSrvcOnsch:"19904", idTipo: 12, nombre: "Junta de Lanzamiento de Promociones", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
    {idSrvcOnsch:"19905", idTipo: 13, nombre: "Junta de Gerentes", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
    {idSrvcOnsch:"19906", idTipo: 14, nombre: "Cumbre de Líderes", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
    {idSrvcOnsch:"19907", idTipo: 15, nombre: "Kick Off", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false}
];

const eventos = {
    "presentador": [ // 1
        {idTipo: 1, nombre: "Demo Thermomix", modalidad1: true, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 2, nombre: "Entrega Thermomix", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 3, nombre: "Contactar", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 4, nombre: "Seguimiento", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 5, nombre: "Evento Personal", modalidad1: false, modalidad2: false, editaTitulo: true, periodicidad: false}
    ],
    "lider": [ // 3
        {idTipo: 1, nombre: "Demo Thermomix", modalidad1: true, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 2, nombre: "Entrega Thermomix", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 3, nombre: "Contactar", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 4, nombre: "Seguimiento", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 5, nombre: "Evento Personal", modalidad1: false, modalidad2: false, editaTitulo: true, periodicidad: false},
        {idTipo: 6, nombre: "Junta General", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true},
        {idTipo: 7, nombre: "Clases de Cocina", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 8, nombre: "Workshop Interno", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true}
    ],
    "gerente": [ // 5
        {idTipo: 1, nombre: "Demo Thermomix", modalidad1: true, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 2, nombre: "Entrega Thermomix", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 3, nombre: "Contactar", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 4, nombre: "Seguimiento", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 5, nombre: "Evento Personal", modalidad1: false, modalidad2: false, editaTitulo: true, periodicidad: false},
        {idTipo: 6, nombre: "Junta General", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true},
        {idTipo: 7, nombre: "Clases de Cocina", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 8, nombre: "Workshop Interno", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true},
        {idTipo: 9, nombre: "Junta de Líderes de Equipo", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 10, nombre: "Junta de Ventas", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 11, nombre: "Taller de Conocimiento de Producto", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true}
    ],
    "manager": [ // 8
        {idTipo: 1, nombre: "Demo Thermomix", modalidad1: true, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 2, nombre: "Entrega Thermomix", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 3, nombre: "Contactar", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 4, nombre: "Seguimiento", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 5, nombre: "Evento Personal", modalidad1: false, modalidad2: false, editaTitulo: true, periodicidad: false},
        {idTipo: 6, nombre: "Junta General", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true},
        {idTipo: 7, nombre: "Clases de Cocina", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 8, nombre: "Workshop Interno", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true},
        {idTipo: 9, nombre: "Junta de Líderes de Equipo", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 10, nombre: "Junta de Ventas", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 11, nombre: "Taller de Conocimiento de Producto", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 12, nombre: "Junta de Lanzamiento de Promociones", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 13, nombre: "Junta de Gerentes", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true}
    ],
    "rgnlMngrYSS": [ // 9
        {idTipo: 1, nombre: "Demo Thermomix", modalidad1: true, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 2, nombre: "Entrega Thermomix", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: false},
        {idTipo: 3, nombre: "Contactar", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 4, nombre: "Seguimiento", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 5, nombre: "Evento Personal", modalidad1: false, modalidad2: false, editaTitulo: true, periodicidad: false},
        {idTipo: 6, nombre: "Junta General", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true},
        {idTipo: 7, nombre: "Clases de Cocina", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 8, nombre: "Workshop Interno", modalidad1: false, modalidad2: true, editaTitulo: true, periodicidad: true},
        {idTipo: 9, nombre: "Junta de Líderes de Equipo", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 10, nombre: "Junta de Ventas", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 11, nombre: "Taller de Conocimiento de Producto", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 12, nombre: "Junta de Lanzamiento de Promociones", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 13, nombre: "Junta de Gerentes", modalidad1: false, modalidad2: true, editaTitulo: false, periodicidad: true},
        {idTipo: 14, nombre: "Cumbre de Líderes", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false},
        {idTipo: 15, nombre: "Kick Off", modalidad1: false, modalidad2: false, editaTitulo: false, periodicidad: false}
    ]
};

const perfiles = [
    {id:1, nombre: "Gourmet"},
    {id:2, nombre: "Veggie/Vegan"},
    {id:3, nombre: "Familiar"},
    {id:4, nombre: "Saludable"},
    {id:5, nombre: "Tecnológico"},
    {id:6, nombre: "Godínez"},
    {id:7, nombre: "Gluten Free"},
    {id:8, nombre: "Keto"},
    {id:9, nombre: "Uso comercial"}
    //{id:10, nombre: "Cancelado"}
];

const calendarioVorwerk = [
    {
        "0": [
            {
                inicio: new Date(new Date().getFullYear()-3, 11, 28, 0, 0)
            },
            {
                fin: new Date(new Date().getFullYear(), 0, 31, 23, 59)
            }
        ]
    },
    {
        "1": [
            {
                inicio: new Date(new Date().getFullYear(), 1, 1, 0, 0)
            },
            {
                fin: new Date(new Date().getFullYear(), 1, 28, 23, 59)
            }
        ]
    },
    {
        "2": [
            {
                inicio: new Date(new Date().getFullYear(), 2, 1, 0, 0)
            },
            {
                fin: new Date(new Date().getFullYear(), 2, 28, 23, 59)
            }
        ]
    },
    {
        "3": [
            {
                inicio: new Date(new Date().getFullYear(), 2, 29, 0, 0)
            },
            {
                fin: new Date(new Date().getFullYear(), 4, 2, 23, 59)
            }
        ]
    },
    {
        "4": [
            {
                inicio: new Date(new Date().getFullYear(), 4, 3, 0, 0)
            },
            {
                fin: new Date(new Date().getFullYear(), 4, 30, 23, 59)
            }
        ]
    },
    {
        "5": [
            {
                inicio: new Date(new Date().getFullYear(), 4, 31, 0, 0)
            },
            {
                fin: new Date(new Date().getFullYear(), 5, 27, 23, 59)
            }
        ]
    },
    {
        "6": [
            {
                inicio: new Date(new Date().getFullYear(), 5, 28, 0, 0)
            },
            {
                fin: new Date(new Date().getFullYear(), 6, 25, 23, 59)  // Fecha de Vorwerk
                //fin: new Date(new Date().getFullYear(), 6, 20, 23, 59)
            }
        ]
    },
    {
        "7": [
            {
                //inicio: new Date(new Date().getFullYear(), 6, 26, 0, 0)
                inicio: new Date(new Date().getFullYear(), 1, 26, 0, 0)
            },
            {
                //fin: new Date(new Date().getFullYear(), 7, 29, 23, 59)
                fin: new Date(new Date().getFullYear(), 1, 29, 23, 59)
            }
        ]
    },
    {
        "8": [
            {
                //inicio: new Date(new Date().getFullYear(), 7, 30, 0, 0)
                inicio: new Date(new Date().getFullYear(), 1, 30, 0, 0)
            },
            {
                //fin: new Date(new Date().getFullYear(), 8, 26, 23, 59)
                fin: new Date(new Date().getFullYear(), 1, 26, 23, 59)
            }
        ]
    },
    {
        "9": [
            {
                //inicio: new Date(new Date().getFullYear(), 8, 27, 0, 0)
                inicio: new Date(new Date().getFullYear(), 1, 27, 0, 0)
            },
            {
                //fin: new Date(new Date().getFullYear(), 9, 31, 23, 59)
                fin: new Date(new Date().getFullYear(), 1, 31, 23, 59)
            }
        ]
    },
    {
        "10": [
            {
                //inicio: new Date(new Date().getFullYear(), 10, 1, 0, 0)
                inicio: new Date(new Date().getFullYear(), 1, 1, 0, 0)
            },
            {
                //fin: new Date(new Date().getFullYear(), 10, 28, 23, 59)
                fin: new Date(new Date().getFullYear(), 1, 28, 23, 59)
            }
        ]
    },
    {
        "11": [
            {
                //inicio: new Date(new Date().getFullYear(), 10, 29, 0, 0)
                inicio: new Date(new Date().getFullYear(), 1, 29, 0, 0)
            },
            {
                //To Do Ver2: Modified to 31 to test results and reading of this values.
                //fin: new Date(new Date().getFullYear(), 11, 31, 23, 59)
                fin: new Date(new Date().getFullYear(), 1, 31, 23, 59)
            }
        ]
    }
];

// Local IP Server
//const ipServer = "http://localhost:4000";
// Development IP Server
// const ipServer = "https://dev-apiagenda.mxthermomix.com";
//IP Server from Env Vars for Pipelines
const ipServer = process.env.REACT_APP_IPSERVER;
// Productive IP Server
//const ipServer = "https://apiagenda.mxthermomix.com";

const CurrentAppVersion = '5.2.2';
const CurrentBuildVersion = '001';

const imageProfilePath = ipServer + '/images/profile?img=';
const imageResourcePath = ipServer + '/images/resource?img=';
const version = 1.2;

function goTop(ref){
    window.scrollTo({
        top:ref.current.offsetTop-200,
        behavior: "smooth"
    })
}

module.exports = {
    version,
    ipServer,
    goTop,
    eventosAll,
    eventos,
    perfiles,
    imageProfilePath,
    imageResourcePath,
    CurrentAppVersion,
    CurrentBuildVersion,
    calendarioVorwerk
};

